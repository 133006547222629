@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */

body {
  @apply bg-gray-100;
}

.btn {
  @apply px-4 py-2 rounded transition duration-300 ease-in-out;
}

.btn-primary {
  @apply bg-[#9a55f4] text-white hover:bg-[#8645e0];
}

/* Transition animations */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* SearchForm styles */
.search-form-container {
  @apply min-h-screen bg-cover bg-center flex items-center justify-center text-white;
  background-image: url('/public/travel-background.png');
}

.content {
  @apply bg-black bg-opacity-50 p-8 rounded-lg w-full max-w-md;
}

.search-form {
  @apply flex flex-col space-y-4;
}

.input-group {
  @apply relative flex items-center;
}

.icon {
  @apply absolute left-3 text-gray-400;
}

.search-form input,
.search-form select {
  @apply w-full py-2 pl-10 pr-3 bg-white bg-opacity-90 rounded-md text-gray-800 focus:ring-[#9a55f4] focus:border-[#9a55f4];
}

.search-button {
  @apply bg-[#9a55f4] text-white py-2 px-4 rounded-md hover:bg-[#8645e0] transition-colors;
}

/* RouteList styles */
.route-item {
  @apply border-b border-gray-200 py-4 cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out;
}

/* RouteDetails styles */
.route-details {
  @apply bg-white rounded-lg shadow-md p-6 mt-4;
}

/* BookingForm styles */
.booking-form {
  @apply bg-white rounded-lg shadow-md p-6 mt-4;
}

/* FilterSort styles */
.filter-sort {
  @apply bg-white rounded-lg shadow-md p-4 mt-4;
}

/* LoadingScreen styles */
.loading-screen {
  @apply flex flex-col items-center justify-center min-h-screen bg-gray-100;
}

/* BookingHistory styles */
.booking-history {
  @apply mt-8;
}

.booking-item {
  @apply bg-white shadow overflow-hidden sm:rounded-lg mb-4;
}

/* LanguageSelector styles */
.language-selector {
  @apply flex space-x-2;
}

.language-button {
  @apply px-2 py-1 rounded;
}

.language-button-active {
  @apply bg-[#9a55f4] text-white;
}

.language-button-inactive {
  @apply bg-gray-200 text-gray-700 hover:bg-gray-300;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .content {
    @apply p-4;
  }

  .search-form input,
  .search-form select {
    @apply py-1;
  }

  .route-details,
  .booking-form,
  .filter-sort {
    @apply p-4;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #964AE9;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

/* Transitions */
.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.transition-opacity {
  transition-property: opacity;
}

.duration-300 {
  transition-duration: 300ms;
}

/* Стили для календаря */
.react-datepicker {
  font-family: inherit !important;
  border-radius: 0.5rem !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.react-datepicker__header {
  background-color: #f9fafb !important;
  border-bottom: 1px solid #e5e7eb !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #9a55f4 !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #8645e0 !important;
  color: white !important;
}

.react-datepicker__day--disabled {
  color: #9ca3af !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__current-month {
  font-weight: 600 !important;
  color: #1f2937 !important;
}

.react-datepicker__day-name {
  color: #6b7280 !important;
}